
@import "../../sass/component.scss";

.v-datatable {
  &__nav {
    display: flex;
    flex: auto;
    height: 60px;
    align-items: center;
    justify-content: flex-end;
    white-space: nowrap;
    user-select: none;
    border-top: 1px solid theme-color("light");
    border-bottom: 1px solid theme-color("light");
  }

  &__loading {
    margin-right: auto;
    margin-left: $spacer * 0.5;
  }

  &__show {
    margin-right: $spacer * 0.5;
  }

  &__pagesize {
    width: 77px;
    height: 34px;
    display: block;
    margin: 0;
    padding: 0;
  }

  &__itemnumbers {
    min-width: 114px;
    padding-left: $spacer;
    text-align: right;
  }

  &__arrows {
    display: flex;
    align-items: center;
    margin-left: $spacer * 0.5;
  }

  &__arrow {
    width: 30px;
    height: 30px;
  }
}
