
@import "../../sass/component.scss";

.v-radio-group {
  &__helper {
    margin-top: 5px;
    font-size: 0.75rem;
    color: theme-color("placeholder");

    .v-radio-group--error & {
      color: theme-color("danger");
    }
  }

  &--error ::v-deep .v-radio__radio {
    border-color: theme-color("danger");
  }
}
