
@import "../../sass/component.scss";

.v-accordion__caret {
  color: theme-color("dark");

  &--small {
    padding: 2px 0;
    transform: rotate(180deg);

    .v-accordion--collapsed & {
      transform: rotate(0);
    }
  }

  &--large {
    width: 16px;

    @include media-breakpoint-down(xs) {
      width: 12px;
    }

    .v-accordion--collapsed & {
      transform: rotate(180deg);
    }

    & path {
      fill: theme-color("primary");

      .v-accordion--collapsed & {
        fill: #8c8c8d;
      }
    }
  }
}
