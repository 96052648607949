.v-navbar-menu-item {
  position: relative;
  user-select: none;
  margin-left: $spacer;
  list-style: none; // remove bullets

  &:first-child,
  .v-navbar-menu-item & {
    margin-left: 0;
  }

  &__items {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 100%);
    z-index: 1001;

    &__wrapper {
      background: #fff;
      border-radius: $border-radius;
    }
  }

  &__link {
    cursor: pointer;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: theme-color("dark");
    touch-action: manipulation;
    -webkit-tap-highlight-color: transparent;
    font-size: 0.875rem;

    svg {
      pointer-events: none;
    }

    @include media-breakpoint-down(sm) {
      font-size: 0.75rem;
      line-height: 1em;

      span,
      a {
        margin-top: 3px;
      }
    }

    .v-navbar-menu-item__locked & {
      color: theme-color("gray-dark");
    }

    ::v-deep {
      img,
      .img {
        background-size: contain;
        margin-bottom: 1px;
        width: 30px;
        height: 30px;

        @include media-breakpoint-down(sm) {
          width: 30px;
          height: 30px;
        }
      }
    }

    a,
    .faux-link {
      display: flex;
      white-space: nowrap;
      color: inherit;
      text-decoration: none;
      align-content: center;
      cursor: pointer;
    }

    svg.v-navbar-menu-item__arrow {
      position: relative;
      top: -1px;
      margin-left: 2px;
      align-self: center;
    }

    svg.v-navbar-menu-item__arrow {
      position: relative;
      top: -1px;
      margin-left: 5px;
    }

    .v-navbar-menu-item__lock {
      position: relative;
      top: 2px;
      margin-right: 5px;
      width: 15px;
      height: 18px;
    }
  }

  &__close {
    height: 30px;
    width: 30px;
  }

  &__language {
    transform: scale(1.1);
  }

  &--navigation &__link {
    font-size: 1rem;
    padding: 2px 1rem 0 1rem;
  }

  &--active > &__link,
  &--active > &__link a {
    font-weight: bold;
    color: theme-color("primary");
  }

  &__items {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 100%);
    display: none;

    @include media-breakpoint-up(md) {
      transform: translate(-85%, 100%);
    }

    .v-navbar-menu-item--open & {
      display: block;

      @include media-breakpoint-up(lg) {
        display: none;
      }
    }

    .v-navbar-menu-item:hover &,
    .v-navbar-menu-item:focus &,
    .v-navbar-menu-item:focus-within & {
      @include media-breakpoint-up(lg) {
        display: block;
      }
    }

    &__wrapper {
      margin-top: $spacer;
      min-width: 220px;
      background: #fff;
      border-radius: $border-radius;
      padding: $spacer;
      box-shadow: 0 18px 40px 0 rgba(0, 0, 0, 0.3);
      position: relative;
      animation-name: reveal;
      animation-duration: 200ms;
      animation-fill-mode: both;
      animation-timing-function: ease-out;

      @include media-breakpoint-up(lg) {
        animation-delay: 100ms;
      }

      &::before {
        content: "";
        width: 20px;
        height: 20px;
        background: #ffff;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -40%) rotate(45deg);
      }

      @include media-breakpoint-up(md) {
        &::before {
          left: 85%;
        }
      }
    }
  }

  &__items &__link {
    display: block;
    font-size: 1rem;
    text-align: left;
    transition: transform 200ms ease-out;
    padding: $spacer * 0.5 0;

    &:hover {
      color: theme-color("primary");
    }
  }

  &.v-navbar-menu-item--horizontal {
    display: inline-flex;

    .v-navbar-menu-item {
      &__link {
        flex-direction: row;

        img {
          width: 20px;
          height: 20px;
          margin-bottom: 0;
          margin-right: 5px;
        }
      }

      &__items {
        transform: translate(-50%, -25px);
        text-align: left;

        &__wrapper {
          margin-top: 0;
          margin-bottom: $spacer;
          animation-name: reveal-up;

          &::before {
            content: "";
            width: 20px;
            height: 20px;
            background: #ffff;
            position: absolute;
            bottom: 0;
            top: auto;
            left: 50%;
            transform: translate(0, 40%) rotate(45deg);
          }
        }
      }
    }
  }
}
