
@import "../../../sass/component.scss";
.v-chat-bubble-teaser {
  position: relative;
  margin-bottom: 0.75 * $spacer;

  ::v-deep {
    .v-chat-bubble {
      transform: translateY(10px);
      opacity: 0;

      .v-chat-teaser--animate & {
        transition: opacity 250ms ease-out, transform 250ms ease-out;
        transform: translateY(0);
        opacity: 1;
      }

      > div {
        width: 100%;
      }

      &__wrapper {
        margin-left: 10px;
      }

      &__content {
        padding: 12px 13px;
        margin-bottom: 0;

        &--member {
          max-width: 200px;
        }

        &--caseworker {
          max-width: 250px;
          background: #ddd;

          &:before {
            background: #ddd;
          }
        }

        &:after {
          background: theme-color("light");
        }
      }

      &__icon {
        margin-bottom: 0;

        svg {
          width: 20px;
        }
      }
    }
  }
}
