
@import "../../sass/component.scss";

.v-display-options {
  display: flex;
  align-items: center;

  &__option {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    color: theme-color("border");
    border: 1px solid theme-color("border");
    border-radius: 5px;
    cursor: pointer;
    margin-left: 8px;

    &--selected {
      color: theme-color("white");
      border-color: theme-color("primary");
      background-color: theme-color("primary");
    }
  }
}
