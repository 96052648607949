:root {
  // Gradient:
  --gradient-direction: to top;
  --gradient-start-position: 25%;
  --gradient-end-position: 80%;
  --gradient-color-start: #{theme-color("light")};
  --gradient-color-end: transparent;

  // Sass Theme Colors converted to CSS Variables:
  @each $color-name, $color-value in $theme-colors {
    --color-#{$color-name}: #{$color-value};
  }

  // Text Shadow:
  --text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);
}
