
@import "../../sass/component.scss";

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.v-button {
  position: relative;
  display: inline-flex;
  border-radius: 1000px;
  cursor: pointer;
  font-weight: bold;
  background-color: theme-color("primary");
  color: #fff !important;
  padding: $spacer * 0.6875 $spacer * 1.5;
  transition: background-color 200ms ease-in-out;
  background: theme-color("primary");
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  user-select: none;
  border: 0;
  font-family: "Lota Grotesque";
  font-size: 1rem;
  line-height: 1.5rem;
  text-decoration: none;
  justify-content: center;

  &__content {
    display: flex;
    align-items: center;

    svg {
      flex-shrink: 0;
      margin-left: $spacer * 0.5;

      &.custom-icon {
        width: auto;
        height: 1.25em;
        margin-inline: -0.25em 0.25em;
        &--clipboard {
          // custom styling for clipboard icon
        }
      }
    }
  }

  &--loading {
    pointer-events: none;
    .v-button__content {
      opacity: 0;
    }
  }

  &:hover {
    background-color: theme-color("primary");
    filter: brightness(0.95);
  }

  &:focus-visible {
    filter: brightness(0.95);
    outline-width: 3px;
    outline-style: solid;
    outline-offset: 0;
  }
  &:focus:not(:focus-visible) {
    outline: none !important;
  }

  &:active {
    background-color: theme-color("primary");
    filter: brightness(0.8);
  }

  &--inverted {
    background-color: #fff;
    color: theme-color("primary") !important;

    &:hover {
      background-color: #fff;
      filter: brightness(0.98);
    }

    &:active {
      background-color: #fff;
      filter: brightness(0.9);
    }
  }

  &--link,
  &--link-icon {
    background: transparent;
    color: theme-color("primary") !important;
    box-shadow: none;
    padding: $spacer * 0.5 $spacer * 1;

    .v-button--secondary & {
      color: theme-color("secondary") !important;
    }

    &:hover,
    &:active {
      background: transparent;
    }
  }

  &--link-icon {
    display: inline-flex;
    justify-content: flex-start;
    padding: $spacer * 0.5 0;

    & .v-button__content {
      svg:first-child {
        margin-left: 0;
        margin-right: $spacer * 0.5;
      }
    }
  }

  &--small {
    font-size: 0.875rem;
    padding: $spacer * 0.375 $spacer;

    svg {
      transform: scale(0.8);
    }
  }

  &--large {
    font-size: 1.125rem;
    padding: $spacer * 0.875 $spacer * 2;
  }

  &--secondary {
    background-color: theme-color("secondary");
    color: #fff !important;

    &:hover {
      background-color: theme-color("secondary");
      filter: brightness(0.95);
    }

    &:active {
      background-color: theme-color("secondary");
      filter: brightness(0.8);
    }

    &.v-button--inverted {
      background-color: #fff;
      color: theme-color("secondary") !important;

      &:hover {
        background-color: #fff;
        filter: brightness(0.98);
      }

      &:active {
        background-color: #fff;
        filter: brightness(0.9);
      }
    }

    &.v-button--link,
    &.v-button--link-icon {
      background: transparent;
      color: theme-color("secondary") !important;
      box-shadow: none;
      padding: $spacer * 0.5 $spacer * 1;

      &:hover,
      &:active {
        background: transparent;
      }
    }
    &.v-button--link-icon {
      padding: $spacer * 0.5 0;
    }
  }

  &:hover:not(.v-button--link) {
    text-decoration: none !important;
  }

  &[disabled] {
    background-color: theme-color("disabled");
    color: theme-color("white") !important;
    box-shadow: none;
    cursor: not-allowed;
  }

  &__spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 20px;
    height: 20px;

    span {
      box-sizing: border-box;
      display: block;
      position: absolute;
      width: 20px;
      height: 20px;
      border: 3px solid #fff;
      border-radius: 50%;
      animation: spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      border-color: #fff transparent transparent transparent;

      &:nth-child(1) {
        animation-delay: -0.45s;
      }

      &:nth-child(2) {
        animation-delay: -0.3s;
      }

      &:nth-child(3) {
        animation-delay: -0.15s;
      }
    }
  }

  @media print {
    // &:not(.v-button--link) {
    //   display: none;
    // }

    &.v-button--link {
      flex-wrap: wrap;

      &[href]:after {
        content: " (" attr(href) ") ";
        width: 100%;
        margin-top: -2px;
        margin-left: 32px;
        font-size: 14px;
        font-weight: normal;
        font-style: italic;
        color: theme-color("dark") !important;
      }
    }
  }
}
