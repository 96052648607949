.v-checkbox-channels {
  display: grid;
  gap: 1rem;
  :deep(.is-not-available) {
    cursor: not-allowed !important;
    pointer-events: initial !important;
    input + div {
      transition: all 0.2s;
    }
    &:hover input + div {
      opacity: 0.5;
      border-color: red;
      background-color: pink;
    }
    small {
      a {
        color: theme-color("primary");
        text-decoration: underline;
      }
    }
  }
}
