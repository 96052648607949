&.v-overlay-card {
  position: relative;
  border-radius: $border-radius;

  &.bg-light {
    --gradient-color-start: #{theme-color("light")};
  }
  &.bg-dark {
    --gradient-color-start: #{theme-color("dark")};
  }

  .overlay-image-wrapper {
    position: unset;
    .ratio-default-picture {
      width: 100%;
      height: auto;
      visibility: hidden;
      pointer-events: none;
    }
    .overlay-picture {
      // min-width of the image files should be 924px to avoid pixelation
      position: absolute;
      inset: 0;
      width: 100%;
      height: 80%;
    }
  }

  .overlay-content {
    opacity: 0.9999; // Opacity hack alternative to position: relative;
  }

  // Wide mode overwrites:
  &--wide-card-mode {
    @include media-breakpoint-up(md) {
      .overlay-image-wrapper {
        .overlay-picture {
          height: 100%;
        }
        .overlay-img {
            width: 75%;
            height: 100%;
          &--x-position-right {
              margin-left: 25%
          }
        }
      }
    }
  }

  // Transitions for all breakpoints:
  .overlay-transition {
    pointer-events: none;
    position: absolute;
    inset: 0;
    background: linear-gradient(
      var(--gradient-direction),
      var(--gradient-color-start) var(--gradient-start-position),
      var(--gradient-color-end) var(--gradient-end-position)
    );
    &--toward-left  {
      @include media-breakpoint-up(md) {
        --gradient-direction: to left;
      }
    }
    &--toward-right  {
      @include media-breakpoint-up(md) {
        --gradient-direction: to right;
      }
    }
  }
}
