
.v-fade {
  position: relative;
  overflow: hidden;
  top: 0;

  &--collapsed {
    user-select: none;

    &:not(.v-fade--clickthrough) {
      pointer-events: none;
    }
  }

  &__fader {
    position: absolute;
    z-index: 20;
    bottom: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(
      to top,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0) 100%
    );

    .v-fade--clickthrough & {
      pointer-events: none;
    }
  }
}
