
@import "../../../sass/component.scss";

.v-chat-print-timestamp {
  position: absolute;
  top: -75px;
  font-size: 14px;
  right: 0;
  z-index: 20000;

  span {
    margin-left: 10px;
  }
}
