
@import "../../sass/component.scss";

.v-quote {
  display: block;
  border: 0;
  padding: 0;
  margin: 0;

  &__icon {
    flex: 0 0 53px;
  }
}
