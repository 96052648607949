
@import "../../sass/component.scss";

.v-cell {
  &__text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 400px;

    .v-cell--overflow & {
      white-space: normal;
      overflow: visible;
      max-width: none;
    }
  }

  &--hide-mobile {
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  &:nth-of-type(1) {
    border-radius: 5px 0 0 5px;
  }

  &:last-of-type {
    border-radius: 0 5px 5px 0;
  }
}
