
@import "../../sass/component.scss";

.v-icon {
  display: inline-flex;
  align-items: center;

  &__content {
    margin-left: 0.5 * $spacer;
  }

  $this: &;

  &__scaler {
    display: inline-flex;
    align-items: center;

    &#{$this}--tiny {
      width: 13px;
    }

    &#{$this}--xxsmall {
      width: 16px;
    }

    &#{$this}--xsmall {
      width: 24px;
    }

    &#{$this}--small {
      width: 40px;
    }

    &#{$this}--medium {
      width: 60px;
    }

    &#{$this}--large {
      width: 80px;
    }
  }

  img,
  svg {
    width: 100%;
  }
}
