
@import "../../../sass/component.scss";

.v-chat-bubble {
  &__align {
    &--right {
      justify-content: flex-end;

      @media print {
        justify-content: flex-start;
      }
    }
  }

  &__icon {
    display: flex;
    flex-shrink: 0;
    align-items: flex-end;
    margin-bottom: 28px;
    z-index: 2;

    svg {
      width: 20pt;
      height: 14pt;
    }
  }

  &__wrapper {
    display: flex;
    margin-left: $spacer;
    min-width: 0;
    flex-direction: column;
  }

  &__meta {
    color: theme-color("placeholder");

    a {
      color: theme-color("placeholder");
      text-decoration: underline;
    }

    &--member {
      text-align: right;
    }
  }

  &__content {
    position: relative;
    padding: $spacer;
    margin-bottom: 7px;
    border-radius: 10px;

    &--member {
      color: theme-color("white");
      background: theme-color("focus");
      border-bottom-right-radius: 14px;

      a {
        color: theme-color("white");
      }
    }

    &--caseworker {
      background: theme-color("light");
      border-bottom-left-radius: 14px;

      a {
        color: theme-color("dark");
      }
    }

    &:before {
      content: "";
      position: absolute;
      z-index: 0;
      bottom: 0;
      height: 20px;
      width: 20px;
    }

    &:after {
      content: "";
      position: absolute;
      z-index: 1;
      bottom: 0;
      width: 16px;
      height: 20px;
      background: theme-color("white");
    }

    &--caseworker {
      &:before {
        left: -7px;
        border-bottom-right-radius: 15px;
        background: theme-color("light");
      }

      &:after {
        left: -16px;
        border-bottom-right-radius: 10px;
      }
    }

    &--member {
      &:before {
        right: -7px;
        border-bottom-left-radius: 15px;
        background: theme-color("focus");
      }

      &:after {
        right: -16px;
        border-bottom-left-radius: 10px;
      }
    }
  }

  @media print {
    // page-break-inside: avoid;
    margin-top: $spacer !important;

    & + & {
      margin-top: $spacer * 1.5 !important;
    }

    > div {
      margin-left: 0;
    }

    &__icon {
      display: none;
    }

    &__wrapper {
      margin-left: 0;
      flex-direction: column-reverse;

      ::v-deep .v-text {
        font-size: 14px;
      }
    }

    &__content {
      background: none;
      color: theme-color("dark");
      padding: 0;

      &:before,
      &:after {
        content: none;
      }
    }
  }
}
