
.v-speak-text {
  display: inline-flex;
  align-items: center;
  cursor: pointer;

  svg {
    margin: 0 4px 1px 0;
  }

  &__label {
    font-size: 0.8rem;
    text-transform: uppercase;
  }
}
