
@import "../../sass/component.scss";

.v-pagination {
  display: flex;
  flex: auto;
  height: 36px;
  align-items: center;
  justify-content: flex-end;
  white-space: nowrap;
  user-select: none;
  margin-left: auto;

  &__show {
    margin-right: $spacer * 0.5;
  }

  &__pagesize {
    width: 77px;

    ::v-deep .v-select__helper {
      display: none;
    }
  }

  &__itemnumbers {
    min-width: 114px;
    padding-left: $spacer;
    text-align: right;
  }

  &__arrows {
    display: flex;
    align-items: center;
    margin-left: $spacer * 0.5;
  }

  &__arrow {
    width: 30px;
    height: 30px;

    &--disabled {
      color: theme-color("border");
      pointer-events: none;
    }
  }
}
