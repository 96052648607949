
@import "../../sass/component.scss";

.v-banner {
  display: flex;
  position: relative;
  border-radius: 0.75rem;
  overflow: hidden;

  &__flex-row {
    flex-direction: row;
  }

  &__flex-row-reverse {
    flex-direction: row-reverse;
  }

  &__image-wrapper {
    width: 50%;
    position: relative;

    &--fade {
      @include media-breakpoint-down(md) {
        position: absolute;
        height: 100%;
      }
    }
  }

  &__image {
    display: block;
    height: 100%;
  }

  &__image-component {
    aspect-ratio: 1 / 0.75;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__image-content {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    padding: 1.5rem;
  }

  &__image-fader {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
  }

  &__content {
    width: 50%;
    padding: 2rem 1.5rem;
  }

  @include media-breakpoint-down(md) {
    flex-direction: column;
    height: auto !important;

    &__content {
      width: 100%;
    }

    &__image-wrapper {
      width: 100%;
    }

    &__image-component {
      aspect-ratio: auto;
    }

    &__image-fader {
      display: none;
    }
  }
}
