
@import "../../sass/component.scss";

.v-focus-mode {
  margin-bottom: 100px;

  @media screen and (max-width: 768px) {
    margin-bottom: 150px;

    &--expanded {
      margin-bottom: 200px;
    }
  }

  &__logo img {
    width: 46px;
    height: 32px;
  }

  &__close {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    background-color: theme-color("gray");
    transition: background-color 200ms ease-out;
    border-radius: 50%;
    cursor: pointer;

    &:hover {
      background-color: theme-color("gray");
      filter: brightness(0.9);
    }
  }
}
