@import "variables";
@import "bootstrap-base";
@import "css-variables";
@import "animations";

html,
body {
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: "Lota Grotesque";
  src: url("./fonts/LotaGrotesque-Bold.woff2") format("woff2"),
    url("./fonts/LotaGrotesque-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Lota Grotesque";
  src: url("./fonts/LotaGrotesque-Regular.woff2") format("woff2"),
    url("./fonts/LotaGrotesque-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.app {
  @import "normalize";
  @import "~bootstrap/scss/grid";
  @import "~bootstrap/scss/utilities/display";
  @import "~bootstrap/scss/utilities/flex";
  @import "~bootstrap/scss/utilities/spacing";
  @import "~bootstrap/scss/utilities/text";
  @import "~bootstrap/scss/utilities/sizing";
  @import "~bootstrap/scss/utilities/background";
  @import "~bootstrap/scss/utilities/borders";

  font-family: "Lota Grotesque", Arial, Helvetica, sans-serif;
  font-size: 1rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.5rem;
  color: theme-color("dark");

  *:focus-visible {
    outline-color: var(--color-focus) !important;
    outline-offset: 0 !important;
  }
  *:focus:not(:focus-visible) {
    outline: none !important;
  }

  &[v-cloak] {
    display: none;
  }

  hr {
    border: 0;
    border-top: 1px solid theme-color("gray");
  }

  address {
    font-style: normal;
  }

  .container-fluid {
    max-width: 1280px;
    padding: 0 $spacer;

    @include media-breakpoint-up(md) {
      padding: 0 $spacer * 1.5;
    }
  }

  .overflow-x-scroll {
    overflow-x: auto;
  }

  .text-capitalize-first::first-letter {
    text-transform: uppercase;
  }

  .valign-justified {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &>*:last-child:not(:first-child) {
      margin-bottom: 0;
      margin-top: 0.5em;
    }
  }
  .position-relative {
    position: relative;
  }
}

@media print {
  body {
    -webkit-print-color-adjust: exact;
  }

  a[href]:after {
    content: none !important;
  }

  @page {
    margin: 2cm 2.5cm !important;
  }
}

// Placeholder for development purpose:
.placeholder-design {
  position: relative;
  background-color: theme-color("light");
  border: 2px dashed lighten(theme-color("dark"), 70%);
  border-radius: $border-radius;
  color: theme-color("dark");
  font-size: clamp(1rem, 2.5vw, 1.5rem);
  line-height: 1.5;
  text-align: center;
  padding: 2em 1em 1.5em;
  margin-block: 1em;
  pointer-events: none;
  p {
    line-height: inherit;
    &:last-child {
      margin-bottom: 0;
    }
    strong {
      border-bottom: 2px solid theme-color("primary");
    }
  }
  &:after {
    content: "Developer Info";
    display: block;
    font-size: clamp(0.7rem, 2vw, 1rem);
    font-weight: bold;
    color: lighten(theme-color("dark"), 65%);
    position: absolute;
    inset: 10px auto auto 10px;
  }
}
