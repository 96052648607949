
@import "../../sass/component.scss";

.v-image-group {
  &__previous {
    width: 50%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    padding-left: $spacer;
    cursor: pointer;

    &::before {
      content: "";
      display: block;
      width: 34px;
      height: 34px;
      background-image: url("./next.svg");
      background-size: contain;
      opacity: 0;
      transition: 200ms opacity ease-out;
      transform: rotate(180deg);
    }

    &:hover {
      &::before {
        opacity: 1;
      }
    }
  }

  &__next {
    width: 50%;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: $spacer;
    cursor: pointer;

    &::before {
      content: "";
      display: block;
      width: 34px;
      height: 34px;
      background-image: url("./next.svg");
      background-size: contain;
      opacity: 0;
      transition: 200ms opacity ease-out;
    }

    &:hover {
      &::before {
        opacity: 1;
      }
    }
  }

  &__wrapper {
    position: relative;
    border-radius: $border-radius;
    overflow: hidden;

    &::before {
      content: "";
      display: block;
      padding-top: 56.25%;
    }
  }

  &__dots {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: $spacer;
  }

  &__description {
    margin-top: $spacer;
  }

  &__dot {
    width: 6px;
    height: 6px;
    background-color: theme-color("gray");
    border-radius: 100%;

    & + & {
      margin-left: calc($spacer / 3);
    }

    &--active {
      background-color: theme-color("dark");
    }
  }

  @media print {
    page-break-inside: avoid;
  }
}
