
@import "../../sass/component.scss";

.v-footer-links {
  font-size: 0.875rem;
  background-color: theme-color("gray");
  border-bottom: 1px solid theme-color("border");
  padding: 3 * $spacer 0;

  @include media-breakpoint-up(lg) {
    padding: 5 * $spacer 0;
  }

  header {
    font-weight: bold;
    margin-bottom: $spacer * 1;
  }

  a {
    color: theme-color("dark");
    display: inline-block;
    float: left;
    clear: left;
    margin-bottom: $spacer * 0.5;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  form {
    display: flex;
    align-items: flex-start;

    ::v-deep .v-button {
      flex-shrink: 0;
    }
  }

  @media print {
    display: none;
  }
}
