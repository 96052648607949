
@import "../../sass/component.scss";

.v-navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1001;
  transition: transform 250ms ease-in-out;
  background-color: theme-color("white");
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  &__content {
    height: $nav-height-mobile;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding: 0 $spacer;

    @include media-breakpoint-up(md) {
      height: $nav-height-desktop;
      padding: 0 $spacer * 1.5;
    }

    @include media-breakpoint-up(lg) {
      // Do we need a new $nav-height- variable here?
    }

    .row {
      padding-top: 8px;

      @include media-breakpoint-up(md) {
        padding-top: 0px;
      }
    }
  }

  &__alerts {
    background: theme-color("primary");
    position: fixed;
    z-index: 1000;
    top: $nav-height-desktop;
    right: 0;
    transition: all 300ms ease-out;

    @include media-breakpoint-down(sm) {
      top: $nav-height-mobile;
    }

    @media print {
      display: none;
    }
  }

  &__alerts--expanded {
    position: initial;
    z-index: 1000;
    top: 0;
    right: 0;
    transition: all 300ms ease-out;
  }

  @media print {
    position: relative;
  }
}
