
@import "../../sass/component.scss";

.v-image {
  position: absolute;
  inset: 0;
  display: none;

  &--active {
    display: block;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
