
@import "../../sass/component.scss";

.v-footer-mobile-tab {
  position: relative;
  border-bottom: 1px solid theme-color("border");

  &--collapsed {
    cursor: pointer;
    user-select: none;
  }

  &__title {
    padding: $spacer;
    font-weight: bold;
    cursor: pointer;
    user-select: none;

    .v-footer-mobile-tab--collapsed & {
      font-weight: normal;
    }
  }

  &__caret {
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    cursor: pointer;
    padding: 14px 12px 0 0;
    color: theme-color("dark");

    svg {
      transform: rotate(180deg);

      .v-footer-mobile-tab--collapsed & {
        transform: none;
      }
    }
  }

  &__content {
    padding: 0 $spacer $spacer $spacer;

    .v-footer-mobile-tab--collapsed & {
      display: none;
    }

    :deep(.text-placeholder) {
      color: theme-color("placeholder") !important;
    }
  }
}
