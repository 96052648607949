// Path
$asset-base-path: "/assets";

@function asset($path) {
  @return url($asset-base-path + $path);
}

// Colors
$theme-colors: (
  "primary": #E7131C,
  "primary-light": #ee666b,
  "primary-lighter": #ffb7ba,
  "secondary": #01426A, // Deprecated
  "secondary-light": #5584a2,
  "info": #0084FF,
  "info-light": #B3D4FF,
  "success": #04AE3A,
  "success-light": #DAF3E2,
  "danger":  #E01836,
  "danger-light": #F9DBDC,
  "warning": #E18B1A,
  "warning-light": #FFEDB2,
  "dark": #282828,
  "black": #000,
  "gray": #E8E8EC,
  "gray-light": #fbfbfb,
  "gray-dark": #545454,
  "light": #F7F7F7,
  "white": #fff,
  "focus": #0084FF,
  "placeholder": #696969,
  "border": #b8b8b8,
  "disabled": #d9d9d9,
);

// List of available font-sizes
$fontSizes: 12 14 16 18 20 24 28 48 64 72 80 96 112 128 144;

// Spacing
$spacer: 1rem;

// Grid
$grid-columns: 12;
$grid-gutter-width: 1rem;

// Spacers
$spacers: () !default;
$spacers: map-merge(
  (
    0: 0,
    1: ($spacer * .25),
    2: ($spacer * .5),
    3: $spacer,
    4: ($spacer * 1.5),
    5: ($spacer * 2),
    6: ($spacer * 2.5),
    7: ($spacer * 3)
  ),
  $spacers
);

// Border radius
$border-radius: .75rem;
$border-radius-lg: $border-radius * 1.5;
$border-radius-sm: $border-radius * 0.5;

// Box shadows
$box-shadow-lg: 0 18px 40px 0 rgba(0,0,0,0.10);

// Navigation
$nav-height-mobile: 60px;
$nav-height-desktop: 76px;
