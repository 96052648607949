
@import "../../sass/component.scss";

$margin: $spacer * 1.875;

.v-card-group {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  margin-top: -$margin;
  margin-left: -$margin;

  ::v-deep .v-card {
    margin-top: $margin;
    margin-left: $margin;
    height: auto;

    @at-root .v-card-group--cols-1#{&} {
      width: calc(100% - #{$margin});
    }

    @at-root .v-card-group--cols-2#{&} {
      width: calc(50% - #{$margin});
    }

    @at-root .v-card-group--cols-3#{&} {
      width: calc(33.33% - #{$margin});
    }

    @at-root .v-card-group--cols-4#{&} {
      width: calc(25% - #{$margin});
    }

    @at-root .v-card-group--cols-5#{&} {
      width: calc(20% - #{$margin});
    }

    @at-root .v-card-group--cols-6#{&} {
      width: calc(16.66% - #{$margin});
    }

    @include media-breakpoint-up(sm) {
      @at-root .v-card-group--cols-sm-1#{&} {
        width: calc(100% - #{$margin});
      }

      @at-root .v-card-group--cols-sm-2#{&} {
        width: calc(50% - #{$margin});
      }

      @at-root .v-card-group--cols-sm-3#{&} {
        width: calc(33.33% - #{$margin});
      }

      @at-root .v-card-group--cols-sm-4#{&} {
        width: calc(25% - #{$margin});
      }

      @at-root .v-card-group--cols-sm-5#{&} {
        width: calc(20% - #{$margin});
      }

      @at-root .v-card-group--cols-sm-6#{&} {
        width: calc(16.66% - #{$margin});
      }
    }

    @include media-breakpoint-up(md) {
      @at-root .v-card-group--cols-md-1#{&} {
        width: calc(100% - #{$margin});
      }

      @at-root .v-card-group--cols-md-2#{&} {
        width: calc(50% - #{$margin});
      }

      @at-root .v-card-group--cols-md-3#{&} {
        width: calc(33.33% - #{$margin});
      }

      @at-root .v-card-group--cols-md-4#{&} {
        width: calc(25% - #{$margin});
      }

      @at-root .v-card-group--cols-md-5#{&} {
        width: calc(20% - #{$margin});
      }

      @at-root .v-card-group--cols-md-6#{&} {
        width: calc(16.66% - #{$margin});
      }
    }

    @include media-breakpoint-up(lg) {
      @at-root .v-card-group--cols-lg-1#{&} {
        width: calc(100% - #{$margin});
      }

      @at-root .v-card-group--cols-lg-2#{&} {
        width: calc(50% - #{$margin});
      }

      @at-root .v-card-group--cols-lg-3#{&} {
        width: calc(33.33% - #{$margin});
      }

      @at-root .v-card-group--cols-lg-4#{&} {
        width: calc(25% - #{$margin});
      }

      @at-root .v-card-group--cols-lg-5#{&} {
        width: calc(20% - #{$margin});
      }

      @at-root .v-card-group--cols-lg-6#{&} {
        width: calc(16.66% - #{$margin});
      }
    }
  }
}
