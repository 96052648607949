
@import "../../sass/component.scss";

.v-radio {
  cursor: default;
  position: relative;

  &__infoIcon {
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 10;

    &--active {
      flex-direction: row;
      top: 24px;
    }
  }

  &__card {
    background-color: theme-color("light");
    padding: 20px;
    border-radius: 10px;

    &--active {
      background-color: theme-color("white");
      box-shadow: 3px 3px 11px -6px black;
    }
  }

  &__wrapper {
    position: relative;
    display: flex;
    align-items: center;
    user-select: none;

    &__card {
      padding-right: 20%;
    }
  }

  &__input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;

    &:checked ~ .v-radio__radio {
      background: theme-color("focus");
      border-color: theme-color("focus");

      &::before {
        background: #fff;
      }
    }

    &:checked:disabled ~ .v-radio__radio {
      background: #fff;
      border-color: theme-color("border");
      cursor: not-allowed;

      &::before {
        background: theme-color("border");
      }
    }

    &:disabled ~ .v-radio__radio {
      cursor: not-allowed;
    }

    &:checked ~ .v-radio__label {
      color: inherit;
    }

    &:disabled ~ .v-radio__label {
      color: theme-color("border");
      cursor: not-allowed;
    }
  }

  &__radio {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    flex-basis: 24px;
    flex-shrink: 0;
    border-radius: 100%;
    background: #fff;
    border: 1px solid theme-color("border");

    &::before {
      content: "";
      display: block;
      width: 8px;
      height: 8px;
      border-radius: 100%;
    }

    &__card {
      position: absolute;
      right: 0;
    }
  }

  &__label {
    margin-left: $spacer;
    color: theme-color("placeholder");
  }
}
