
@import "../../sass/component.scss";

.v-signup-price {
  &__mobile {
    &__btn {
      display: none;
    }
  }
}

@media screen and (max-width: 768px) {
  .v-signup-price__wrapper {
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 110;
    width: 100%;
  }

  .v-signup-price {
    &__mobile {
      background-color: theme-color("dark");
      padding: 16px;
      color: theme-color("light");

      &--shrink {
        height: 50px;
        padding: 6px 16px;
      }

      &__content-wrapper {
        display: flex;
        flex-direction: column;

        &--shrink {
          flex-direction: row;
          gap: 10px;
        }

        &__title {
          margin-bottom: 5px;

          &--shrink {
            display: none;
          }
        }

        &__content {
          margin: 0;

          &__price {
            font-weight: 700;

            &--shrink {
              font-weight: 400;
            }
          }
        }
      }

      &__description--shrink {
        display: none;
      }

      &__btn {
        display: block;
        text-decoration: underline;
        position: absolute;
        font-size: 0.875rem;
        right: 20px;
        color: theme-color("light");
        z-index: 2;
        cursor: pointer;
      }
    }
  }
}
