
@import "../../../sass/component.scss";

.v-chat-typing-indicator {
  position: absolute;
  top: 0;
  left: 30px;
  opacity: 0;

  .v-chat-teaser--animate & {
    transition: opacity 200ms ease-out;
    opacity: 1;
  }

  > div {
    $bg-color: #ddd;
    background-color: $bg-color;
    width: auto;
    border-radius: 20px;
    padding: 9px 7px;
    display: table;
    position: relative;
    animation: 2s bulge infinite ease-out;

    &:before,
    &:after {
      content: "";
      position: absolute;
      bottom: 0px;
      left: 0px;
      height: 8px;
      width: 8px;
      border-radius: 50%;
      background-color: $bg-color;
    }

    &:after {
      height: 5px;
      width: 5px;
      left: -4px;
      bottom: -4px;
    }

    span {
      height: 6px;
      width: 6px;
      float: left;
      margin: 0 1px;
      background-color: theme-color("placeholder");
      display: block;
      border-radius: 50%;
      opacity: 0.4;

      @for $i from 1 through 3 {
        &:nth-of-type(#{$i}) {
          animation: 1s blink infinite ($i * 0.3333s);
        }
      }
    }
  }

  @keyframes blink {
    50% {
      opacity: 1;
    }
  }

  @keyframes bulge {
    50% {
      transform: scale(1.05);
    }
  }
}
