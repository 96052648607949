
@import "../../sass/component.scss";

.v-accordion-group {
  width: 100%;

  ::v-deep {
    .v-accordion + .v-accordion {
      margin-top: $spacer;
    }
  }
}
