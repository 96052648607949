
@import "../../sass/component.scss";

.v-tabs {
  &__titles {
    &__wrapper {
      display: flex;
      overflow-x: auto;
      border-bottom: 1px solid #d8d8d8;
      padding-bottom: 1px;
    }

    &--overflow {
      position: relative;
      margin-right: -$spacer;

      &:after {
        content: "";
        position: absolute;
        height: 100%;
        width: 80px;
        top: 0;
        right: 0;
        pointer-events: none;
        background: linear-gradient(
          to left,
          rgba(255, 255, 255, 1) 0%,
          rgba(255, 255, 255, 0) 100%
        );
      }

      & .v-tabs__titles__wrapper:after {
        content: "";
        padding-right: 80px;
      }
    }

    @media print {
      display: none;
    }
  }

  &__title {
    position: relative;
    top: 1px;
    white-space: nowrap;
    padding-bottom: $spacer * 0.5;
    border-bottom: 2px solid transparent;
    cursor: pointer;
    user-select: none;
    color: theme-color("black");
    text-decoration: none !important;

    & + & {
      margin-left: $spacer * 2;
    }

    &--active {
      font-weight: normal;
      color: darken(theme-color("primary"), 7%);
      border-color: theme-color("primary");
    }
  }

  &__content {
    color: theme-color("black");

    :deep(.text-placeholder) {
      color: theme-color("placeholder") !important;
    }
  }

  @media print {
    margin-top: 0 !important;
  }
}
