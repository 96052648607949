
@import "../../sass/component.scss";

// Mixin that generates all the classes
@mixin textSizeClasses($breakpoint: null) {
  @each $size in $fontSizes {
    $index: index($fontSizes, $size);

    &--size#{if($breakpoint, '-' + $breakpoint, '')}-#{$index} {
      // Font size
      @include font-size($size);

      // Line height
      @if $index >= 9 {
        line-height: 1;
      } @else if $index == 8 {
        line-height: 1.125;
      } @else if $index >= 6 {
        line-height: 1.25;
      } @else {
        line-height: 1.375;
      }

      // Letter spacing
      @if $index >= 7 {
        letter-spacing: -0.5px;
      } @else {
        letter-spacing: normal;
      }
    }
  }
}

.v-text {
  // Make sure font-weight is default as normal (h1, h2, h3)
  position: relative;
  font-weight: normal;
  margin: 0;
  padding: 0;
  border: 0;

  //Fix flex layoyt text overflow issue in IE11
  max-width: 100%;

  // Size classes for small and up
  @include textSizeClasses();

  // Size classes for medium and up
  @include media-breakpoint-up(md) {
    @include textSizeClasses(md);
  }

  // Size classes for large and up
  @include media-breakpoint-up(lg) {
    @include textSizeClasses(lg);
  }

  &--linebreaks {
    white-space: pre-wrap;
  }

  &--inactive {
    color: theme-color("disabled");
    .v-card & {
      color: darken(theme-color("disabled"), 15%);
    }
  }

  &--frontpage {
    font-size: 24px;
    line-height: 1.25;

    @include media-breakpoint-up(md) {
      font-size: 36px;
    }
  }

  &__icon {
    &--link {
      display: none !important;
    }
  }

  // Override Vuepress styling
  ::v-deep {
    p,
    ol,
    ul {
      line-height: inherit;
    }

    // Headings
    h1 {
      &:not(.v-text) {
        @include font-size(nth($fontSizes, 8));
        margin-bottom: 0.25em;
        line-height: 1.125;
        padding: 0;
        border: 0;
        font-weight: bold;
      }
    }

    h2 {
      &:not(.v-text) {
        @include font-size(nth($fontSizes, 7));
        margin-bottom: 0.5em;
        line-height: 1.25;
        padding: 0;
        border: 0;
        font-weight: bold;
      }
    }

    h3 {
      &:not(.v-text) {
        @include font-size(nth($fontSizes, 6));
        line-height: 1.25;
        margin-bottom: 0.5em;
        padding: 0;
        border: 0;
        font-weight: bold;
      }
    }

    h4 {
      &:not(.v-text) {
        @include font-size(nth($fontSizes, 5));
        line-height: 1.5;
        margin-bottom: 0.5em;
        padding: 0;
        border: 0;
        font-weight: bold;
      }
    }

    h5 {
      &:not(.v-text) {
        @include font-size(nth($fontSizes, 4));
        line-height: 1.5;
        margin-bottom: 0.5em;
        padding: 0;
        border: 0;
        font-weight: bold;
      }
    }

    h6 {
      &:not(.v-text) {
        @include font-size(nth($fontSizes, 3));
        line-height: 1.5;
        margin-bottom: 0.5em;
        padding: 0;
        border: 0;
        font-weight: bold;
      }
    }

    // Links
    a {
      color: theme-color("primary");
      text-decoration: none;

      &.text-decoration-underline {
        text-decoration: underline;
      }

      &:hover {
        text-decoration: underline;
      }

      &:visited {
        color: theme-color("primary");
        filter: brightness(0.8);
      }
    }

    // Lists
    ul {
      list-style: none;
      padding: 0;

      li {
        position: relative;
        padding-left: 30px;

        & + li {
          margin-top: $spacer * 0.5;
        }

        &::before {
          position: absolute;
          left: 5px;
          top: 0.5em;
          content: "";
          display: block;
          width: 6px;
          height: 6px;
          background-color: currentColor;
          border-radius: 50%;
        }
      }
    }

    ul.usp-list,
    ul.usp-list-animated {
      li {
        &::before {
          top: 0.35em;
          width: 12px;
          height: 12px;
          background-color: transparent;
          background-image: url("./assets/checkmark.svg");
          background-repeat: no-repeat;
          background-size: contain;
        }

        &.no-checkmark::before {
          background-image: none;
        }
      }
    }

    ul.usp-list-animated {
      li {
        transition-property: opacity, transform;
        transition-duration: 400ms;
        transition-timing-function: ease-in-out;
        transform: translateY(15px);
        opacity: 0;
      }

      &.animate li {
        transform: translateY(0);
        opacity: 1;
      }
    }

    ol {
      list-style: none;
      padding: 0;

      li {
        position: relative;
        padding-left: 30px;
        counter-increment: step-counter;

        & + li {
          margin-top: $spacer * 0.5;
        }

        &::before {
          position: absolute;
          left: 5px;
          top: 0;
          content: counter(step-counter) ".";
          display: block;
        }
      }
    }
  }

  &__tooltip {
    top: 3px !important;
    right: -5px !important;
  }

  @media print {
    page-break-inside: avoid;

    &--pagebreak {
      page-break-inside: auto;
    }

    a[href]:not(.v-text) {
      color: theme-color("dark");
      text-decoration: underline;

      &:after {
        content: " (" attr(href) ") ";
        font-style: italic;
      }
    }
  }
}

// Hack to avoid page break right after heading
@media print {
  h1.v-text,
  h2.v-text,
  h3.v-text {
    &::after {
      content: "";
      display: block;
      height: 200px;
      margin-bottom: -200px;
    }

    page-break-inside: avoid;
  }
}
// Links
a[target="_blank"] {
  &:not(.v-button) {
    .v-text__icon--link {
      display: inline-block !important;
    }
  }
}
