
@import "../../sass/component.scss";

.v-footer-address {
  background-color: theme-color("gray");
  text-align: center;

  .row {
    align-items: center;
    padding: 3 * $spacer 0;
  }

  &__language,
  &__social {
    display: flex;
    justify-content: center;
    margin-bottom: $spacer * 1.5;

    @include media-breakpoint-up(md) {
      margin-bottom: 0;
    }
  }

  &__language {
    list-style: none;
    @include media-breakpoint-up(md) {
      justify-content: flex-end;
    }
  }

  &__social {
    align-items: center;

    a {
      display: inline-flex;
      margin-left: 10px;
      margin-right: 10px;

      @include media-breakpoint-up(md) {
        margin-left: 0;
        margin-right: 20px;
      }
    }

    @include media-breakpoint-up(md) {
      justify-content: flex-start;
    }
  }

  &__content {
    font-size: 0.75rem;

    span {
      margin-left: 0.25 * $spacer;
      margin-right: 0.25 * $spacer;
    }

    a {
      color: theme-color("dark");
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  @media print {
    display: none;
  }
}
