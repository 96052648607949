
@import "../../sass/component.scss";

.v-layout {
  padding-block-start: var(--current-navbar-height);
  transition: padding-block-start 0.3s ease-in-out;

  @media print {
    padding-block-start: 0;
  }

  &__contentJump {
    border: 2px black solid;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
    padding: 10px;
    position: absolute;
    top: -100px;
    left: 15%;
    margin-top: 5px;
    background-color: theme-color("white");
    transform: translate(-50%, calc(-100% - 12px));
    z-index: 10000;
    transition: 0.3s ease-in-out all;

    &:hover {
      .v-layout__contentJump__clickable {
        text-decoration: underline;
      }
    }

    &:focus {
      top: 0;
      transform: translate(-50%, 0%);
    }
  }

  &--vuepress {
    padding-block-start: 0;
  }

  &--hideNavbar {
    padding-block-start: 0;
  }

  &__hidden-alerts {
    position: absolute;
    margin-left: -10000px;
    text-size-adjust: none;

    ::v-deep .v-alerts__content > .v-text {
      display: block !important;
    }
  }
}

.notifications {
  position: fixed;
  z-index: 2000;
  padding: 0 $spacer;
  right: 0;
  bottom: 0;

  ::v-deep .vue-portal-target {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-end;
  }
}
