
@import "../../sass/component.scss";

.v-truncate {
  &__middle {
    display: flex;

    &__left {
      flex: 0 1 auto;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &__right {
      flex: 1 0 auto;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  &__slot {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
