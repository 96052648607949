.v-toggle {
  &__label {
    margin-right: $spacer;
    color: theme-color("placeholder");
    flex-grow: 1;
    :deep(a) {
      display: inline-block;
      padding: 2px;
      margin: -2px;
      color: theme-color("primary");
      pointer-events: all;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__wrapper {
    position: relative;
    display: flex;
    align-items: center;
    user-select: none;

    &--loading {
      pointer-events: none;
    }
  }

  &__input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;

    &:checked ~ .v-toggle__toggle {
      background: theme-color("focus");
      border-color: theme-color("focus");

      &--primary {
        background: theme-color("primary");
        border-color: theme-color("primary");
      }

      &::before {
        transform: translateX(20px);
      }
    }

    &:disabled ~ .v-toggle__toggle {
      cursor: not-allowed;
      opacity: 0.8;
    }

    &:checked:disabled ~ .v-toggle__toggle {
      background: #ccc;
      border-color: #ababab;
      &::before {
        background-color: #fafafa;
      }
    }

    &:not(:checked):disabled ~ .v-toggle__toggle {
      background: #f6f6f6;
      border-color: #ddd;
      &::before {
        background-color: #fafafa;
      }
    }

    &:disabled ~ .v-toggle__label {
      cursor: not-allowed;
      color: theme-color("border");
    }

    &:checked ~ .v-toggle__label {
      color: inherit;
    }

    &:checked:disabled ~ .v-toggle__label {
      color: theme-color("border");
    }
  }

  &__loading {
    position: relative;
    padding: 0 10px;
  }

  &__toggle {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 30px;
    flex-shrink: 0;
    border-radius: 15px;
    background: #fff;
    border: 1px solid theme-color("border");
    transition: background-color 200ms ease-in-out, border 200ms ease-in-out;
    cursor: pointer;
    /* overflow: hidden; */

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 28px;
      height: 28px;
      border-radius: 14px;
      background-color: #fff;
      box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.5);
      transition: transform 200ms ease-in-out;
    }
  }
}
