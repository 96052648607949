
@import "../../../sass/component.scss";

.v-navbar-navigation {
  -webkit-overflow-scrolling: touch;
  background: theme-color("white");
  margin-top: 10px;
  margin-left: -$spacer;
  margin-right: -$spacer;
  border-top: 1px solid theme-color("light");
  border-bottom: 1px solid theme-color("light");

  @include media-breakpoint-up(md) {
    margin-top: 0;
    background: none;
    border: none;
  }

  nav {
    margin: 0 auto;

    @include media-breakpoint-up(sm) {
      width: 80%;
    }
  }
}
