
@import "../../../sass/component.scss";

.v-navbar-notifications-item {
  display: block;
  color: theme-color("dark");
  padding: $spacer;
  background-color: theme-color("white");

  & + & {
    border-top: 1px solid theme-color("gray");
  }

  &:hover {
    background-color: theme-color("light");
  }

  &--unread {
    background-color: #e5f2ff !important;

    &:hover {
      background-color: #deebf8 !important;
    }
  }
}
