
@import "../../../../sass/component.scss";

.v-checkbox-group {
  &__helper {
    margin-top: 5px;
    font-size: 0.75rem;
    color: theme-color("placeholder");

    .v-checkbox-group--error & {
      color: theme-color("danger");
    }
  }

  &--error ::v-deep .v-checkbox__checkbox {
    border-color: theme-color("danger");
  }
}
