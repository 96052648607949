
@import "../../sass/component.scss";

.v-stack {
  &__content {
    > :not(code) ~ :not(code) {
      .v-stack--xxsmall > & {
        margin-top: map-get($spacers, 2);
      }
      .v-stack--xsmall > & {
        margin-top: map-get($spacers, 3);
      }
      .v-stack--small > & {
        margin-top: map-get($spacers, 4);
      }
      .v-stack--medium > & {
        margin-top: map-get($spacers, 5);
      }
      .v-stack--large > & {
        margin-top: map-get($spacers, 6);
      }
      .v-stack--xlarge > & {
        margin-top: map-get($spacers, 7);
      }
    }
  }
}
