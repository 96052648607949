.v-inpage-links {
  padding-inline-start: 1.25rem;
  border-inline-start: 4px solid theme-color('primary');

  ul {
    padding-inline: 0;
    margin-block: 1rem 0;
    list-style: none;

    li:not(:last-child) {
      margin-block-end: 0.5rem;
    }

    a {
      text-underline-offset: 0.2em;
      color: theme-color('primary');
      text-decoration: none;
      @include media-breakpoint-up(md) {
        font-size: 1.125rem;
      }
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
