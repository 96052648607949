
.tooltip.v-tooltip-3f.v-tooltip-3f--infoicon {
  a {
    color: white;
    text-decoration: underline;
  }

  .wrapper {
    position: relative;
  }

  &[x-placement^="left"] {
    margin: 0;
    padding-right: 12px;
  }

  &[x-placement^="right"] {
    margin: 0;
    padding-left: 12px;
  }

  &[x-placement^="top"] {
    margin: 0;
    padding-bottom: 12px;
  }

  &[x-placement^="bottom"] {
    margin: 0;
    padding-top: 12px;
  }
}
