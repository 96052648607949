
.v-arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 12px;
  height: 12px;
  cursor: pointer;

  &.v-arrow__inverted {
    width: 26px;
    height: 26px;
  }

  &__up {
    transform: rotate(90deg);
  }

  &__right {
    transform: rotate(180deg);
  }

  &__down {
    transform: rotate(270deg);
  }
}
