
@import "../../../sass/component.scss";
.v-chat-teaser {
  max-width: 380px;
  margin: 0 auto;
  padding-bottom: 16px;

  @include media-breakpoint-up(md) {
    padding: 30px 30px 30px 0;
  }
}
