.v-alert-local {
  --color-warning-fg: 118, 60, 25;
  --color-warning-bg: 253, 253, 235;
  --color-success-fg: 0, 84, 64;
  --color-success-bg: 242, 249, 247;
  --color-error-fg: 161, 29, 32;
  --color-error-bg: 254, 242, 242;
  --color-mobilepay-fg: 14, 66, 156;
  --color-mobilepay-bg: 234, 245, 254;
  --theme-color-fg: 14, 66, 156; // default for info alert
  --theme-color-bg: 234, 245, 254; // default for info alert

  --theme-bg: rgba(var(--theme-color-bg), 1);
  --theme-border: rgba(var(--theme-color-fg), 0.1);
  --theme-text: rgba(var(--theme-color-fg), 1);
  --theme-icon: rgba(var(--theme-color-fg), 0.6);

  contain: layout;
  position: relative; // fallback for older Safari versions not supporting contain
  padding: $spacer;
  border-radius: $border-radius * 0.5;
  transition: all 300ms ease-out;
  background-color: var(--theme-bg);
  border: 1px solid var(--theme-border);

  &--warning {
    --theme-color-fg: var(--color-warning-fg);
    --theme-color-bg: var(--color-warning-bg);
  }

  &--success {
    --theme-color-fg: var(--color-success-fg);
    --theme-color-bg: var(--color-success-bg);
  }

  &--error {
    --theme-color-fg: var(--color-error-fg);
    --theme-color-bg: var(--color-error-bg);
  }

  &--mobilepay {
    --theme-color-fg: var(--color-mobilepay-fg);
    --theme-color-bg: var(--color-mobilepay-bg);
    img {
      transform: scale(1.1);
    }
  }

  &-leave-to {
    opacity: 0;
  }

  &__icon {
    position: absolute;
    top: 0.9 * $spacer;
    left: $spacer;
    display: flex;
    justify-content: center;
    pointer-events: none;
    img {
      width: 24px;
      height: 24px;
    }
    svg {
      color: var(--theme-icon);
      fill: currentColor;
      width: 24px;
      height: 24px;
    }
  }

  &__content {
    padding: 0 0px 0 24px;
    margin: 0 $spacer 0 $spacer;
    width: 95%;
    color: var(--theme-text);

    @include media-breakpoint-down(sm) {
      width: 90%;
    }

    ::v-deep {
      a:not(.v-button),
      a:not(.v-button):visited {
        color: var(--theme-text);
        text-decoration: underline;
      }
    }
  }

  &__footer { // for buttons
    display: flex;
    gap: $spacer;
    flex-wrap: wrap;
    width: 95%;
    @include media-breakpoint-down(sm) {
      width: 90%;
    }
    &:not(:empty) {
      margin-block-start: $spacer;
      margin-inline-start: $spacer;
      padding-inline-start: 24px;
    }
    ::v-deep {
      // override v-button styles
      .v-button {
        color: var(--theme-text) !important;
        box-shadow: none;
        opacity: 0.8;
        &:not(.v-button--link) {
          background: transparent;
          border: 2px solid var(--theme-text);
          &:hover {
            background-color: rgba(var(--theme-color-fg), 0.1);
          }
        }
        &--link {
          &:not([class^="p"]) { // Only if padding class is not set
            padding-inline: 0;
          }
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  &__close-button {
    display: flex;
    position: absolute;
    right: 0;
    top: 0;
    padding: 10px;
    border: none;
    cursor: pointer;
    background: none;
    ::v-deep svg {
      color: var(--theme-icon);
    }
  }
}
