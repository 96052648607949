
@import "../../sass/component.scss";

.v-content {
  position: relative;

  @media print {
    .col-md-9,
    .col-md-8,
    .col-lg-7 {
      flex: initial !important;
      max-width: 100% !important;
    }
  }

  .hide-sidebar {
    height: 0;
    overflow: hidden;
  }
}
