
@import "../../sass/component.scss";

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.v-loading {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__spinner {
    position: relative;
    width: 30px;
    height: 30px;
    flex-shrink: 0;

    span {
      box-sizing: border-box;
      display: block;
      position: absolute;
      width: 30px;
      height: 30px;
      border: 3px solid theme-color("primary");
      border-radius: 50%;
      animation: spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      border-color: theme-color("primary") transparent transparent transparent;

      &:nth-child(1) {
        animation-delay: -0.45s;
      }

      &:nth-child(2) {
        animation-delay: -0.3s;
      }

      &:nth-child(3) {
        animation-delay: -0.15s;
      }
    }

    .v-loading--small & {
      &,
      span {
        width: 16px;
        height: 16px;
      }

      span {
        border-width: 2px;
      }
    }

    .v-loading--medium & {
      &,
      span {
        width: 22px;
        height: 22px;
      }
    }
  }

  &__content {
    margin-top: $spacer;
    max-width: 400px;
    text-align: center;
  }
}
