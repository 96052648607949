
@import "../../../sass/component.scss";

.pagination {
  display: flex;
  justify-content: center;
  gap: 10px;

  &--default {
    background-color: transparent;
  }

  &__dot {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.2s;

    &--gray {
      background-color: theme-color("gray");
    }

    &--white {
      background-color: theme-color("white");
    }

    &--ACTIVE {
      background-color: theme-color("secondary");
    }
  }
}
