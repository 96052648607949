.v-contact-card {
  $card-breakpoint: 500px;
  display: grid;
  grid-template-areas: "stack";
  container: component / inline-size;
  background-color: theme-color("light");
  border-radius: $border-radius;
  overflow: clip;
  color: inherit;

  .content {
    grid-area: stack;
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas:
      "label"
      "header"
      "address"
      "cta";
    gap: .5rem;
    padding: 1.5rem 1rem;

    @container component (min-width: #{$card-breakpoint}) {
      padding: 2rem 1.5rem;
      &.aside-style {
        grid-template-columns: 2fr 1fr;
        grid-template-areas:
          "label cta"
          "header cta"
          "address cta";
      }
    }
  }

  .label {
    grid-area: label;
    margin: 0;
    opacity: 0.6;
    font-size: 90%;
    pointer-events: none;
  }

  .header {
    grid-area: header;
  }

  .address {
    grid-area: address;
  }

  .footer {
    grid-area: cta;

    &.aside-style {
      @container component (min-width: #{$card-breakpoint}) {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
      }
    }
  }

  .picture {
    grid-area: stack;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    img {
      width: 100%;
      height: 100%;
      display: block;
      object-fit: cover;
      object-position: 70% bottom;
    }
  }

  // editorial items:
  .v-button {
    &--link {
      padding: 0 !important;
    }
    &:not(.v-button--link) {
      @container component (max-width: #{$card-breakpoint}) {
        margin-block-start: 1rem;
      }
    }
  }

  p {
    &:first-child {
      margin-block-start: 0;
    }
    &:last-child {
      margin-block-end: 0;
    }
  }


}
