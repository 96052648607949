.v-navbar-menu {
  margin-top: -8px;

  @include media-breakpoint-up(md) {
    margin-top: 2px;
  }

  &__wrapper {
    align-self: flex-start;
    height: 40px;
    padding-top: 10px;
  }
}
