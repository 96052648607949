
@import "../../sass/component.scss";

$mobile-offset: $nav-height-mobile + 16;
$desktop-offset: $nav-height-desktop + 24;

.v-anchor:target {
  content: "";
  display: block;
  pointer-events: none;
  height: $mobile-offset;
  margin-top: -$mobile-offset;

  @include media-breakpoint-up(md) {
    height: $desktop-offset;
    margin-top: -$desktop-offset;
  }
}
