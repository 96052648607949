
@import "../../sass/component.scss";

.v-input {
  display: inherit;
  position: relative;

  &__clear {
    cursor: pointer;
    position: absolute;
    right: 5px;
    top: 3px;
    padding: 12px 10px 10px 10px;
    z-index: 50;

    &__icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 20px;
      height: 20px;
      color: theme-color("white");
      background-color: theme-color("border");
      cursor: pointer;
      transition: background-color 200ms ease-out;
      border-radius: 50%;

      &:hover {
        background-color: darken(theme-color("border"), 10%);
      }
    }
  }

  &__textarea {
    height: 150px;

    .v-input__label {
      white-space: wrap;
    }
  }

  &--grow {
    height: auto;
  }

  &__slot {
    .v-input__input & {
      display: none;
    }
  }

  &__wrapper {
    border-radius: 10px;
    display: inline-flex;
    position: relative;
    width: 100%;
    height: 100%;
    background-color: theme-color("white");

    input:autofill {
      color: red;
    }

    .v-input__textarea:not(.v-input--grow) & {
      padding-right: 1px;
      padding-bottom: 10px;
    }
  }

  &-outline {
    position: absolute;
    display: flex;
    right: 0;
    left: 0;
    width: 100%;
    max-width: 100%;
    height: 100%;
    border: 0 solid theme-color("border");

    .v-input--disabled & {
      border-color: theme-color("disabled");
    }

    &__leading,
    &__notch,
    &__trailing {
      height: 100%;
      border: 1px solid;
      border-color: inherit;
    }

    &__leading {
      border-right: none;
      border-radius: 5px 0 0 5px;
      width: 12px;

      .v-input--small & {
        width: 4px;
      }
    }

    &__notch {
      flex: 0 0 auto;
      width: auto;
      border-left: none;
      border-right: none;
      max-width: calc(100% - 12px * 2);
    }

    &__trailing {
      border-left: none;
      border-radius: 0 5px 5px 0;
      flex-grow: 1;
    }

    .v-input--filled.v-input--error &,
    .v-input--error & {
      border-color: theme-color("danger");
      color: theme-color("danger");
    }
  }

  &__label {
    display: inline-block;
    position: relative;
    top: 13px;
    right: auto;
    bottom: auto;
    max-width: 100%;
    color: theme-color("placeholder");
    background: transparent;
    padding: 0 5px;
    transform-origin: left top;
    transition: all 200ms ease-out;
    cursor: text;
    pointer-events: none;
    white-space: nowrap;

    .v-input--error & {
      color: theme-color("danger");
    }

    .v-input--small & {
      top: 4px;
      font-size: 0.875rem;
    }

    .v-input--disabled & {
      color: theme-color("border");
    }
  }

  &__helper {
    margin-top: 5px;
    font-size: 0.75rem;
    color: theme-color("placeholder");

    .v-input--error & {
      color: theme-color("danger");
    }
  }

  &__element {
    width: 100%;
    font-size: 1rem;
    z-index: 1;
    border: none !important;
    outline: 0;
    background: transparent;
    transition: all 200ms ease-out;
    font-family: "Lota Grotesque", Arial, Helvetica, sans-serif;

    .v-input__textarea & {
      height: 100%;
      padding: 12px 10px;
      margin-top: 6px;
      line-height: 1.5em;
      overflow: auto;
      resize: none;
    }

    .v-input--grow & {
      overflow: hidden;
      height: 50px;
      min-height: 29px;
      padding: 2px 5px;
      margin: 10px 5px;
    }

    .v-input__input & {
      height: 50px;
      padding: 0 10px;
      line-height: 1em;

      // IE11/Edge specific
      &::-ms-clear,
      &::-ms-reveal {
        display: none;
      }

      &:invalid {
        box-shadow: none;
      }
    }

    .v-input--small & {
      height: 34px;
      font-size: 0.875rem;
    }

    .v-input--tooltip & {
      margin-right: 40px;
    }

    &:disabled {
      cursor: not-allowed;
      /* required on iOS */
      -webkit-text-fill-color: theme-color("border");
      opacity: 1;

      .v-input__input & {
        color: theme-color("border");
      }

      .v-input__textarea & {
        color: theme-color("border");
      }
    }

    &:disabled ~ .v-input-outline .v-input__label {
      cursor: not-allowed;
    }

    &:focus ~ .v-input-outline {
      border-color: theme-color("focus");
      //box-shadow: 0 0 0 1px theme-color("focus");

      .v-input--error & {
        border-color: theme-color("danger");
        //box-shadow: 0 0 0 1px theme-color("danger");
      }

      .v-input-outline__notch {
        border-top: none;
      }

      .v-input__label {
        font-size: 0.75rem;
        transform: translateY(-25px);
        color: theme-color("focus");

        .v-input--error & {
          color: theme-color("danger");
        }

        .v-input--small & {
          transform: translateY(-18px);
        }
      }
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      transition: background-color 5000s ease-in-out 0s;
    }
  }

  &.v-input--filled .v-input-outline {
    .v-input-outline__notch {
      border-top: none;
    }
    .v-input__label {
      font-size: 0.75rem;
      transform: translateY(-25px);
    }
  }

  &.v-input--filled.v-input--small .v-input-outline {
    .v-input__label {
      transform: translateY(-18px);
    }
  }
}
