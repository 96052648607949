
@import "../../sass/component.scss";

.v-footer-contact-hours {
  position: relative;

  &__title {
    display: inline-flex;
    position: relative;
    color: darken(theme-color("primary"), 7%);
    user-select: none;
    cursor: pointer;
  }

  &__caret {
    display: flex;
    align-items: center;
    margin-left: 5px;
    pointer-events: none;
  }

  &__detailed {
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translateY(100%);
    z-index: 1000;
    width: calc(100vw - 3rem);

    @include media-breakpoint-up(md) {
      left: 50%;
      transform: translate(-50%, 100%);
      width: auto;
      min-width: 335px;
    }

    &__day {
      font-weight: bold;

      @include media-breakpoint-up(sm) {
        font-weight: normal;
      }
    }

    &__wrapper {
      margin-top: $spacer;
      background: #fff;
      border-radius: $border-radius;
      padding: $spacer;
      box-shadow: 0 18px 40px 0 rgba(0, 0, 0, 0.3);
      position: relative;
      animation-name: reveal;
      animation-duration: 200ms;
      animation-timing-function: ease-out;

      &::before {
        content: "";
        width: 20px;
        height: 20px;
        background: #ffff;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -40%) rotate(45deg);
      }
    }
  }
}
