
@import "../../sass/component.scss";

.v-navbar-logo {
  line-height: 1em;
  color: theme-color("dark");
  text-decoration: none;
  display: flex;
  align-items: flex-end;
  font-size: 0.875rem;
  top: -3px;

  @include media-breakpoint-up(md) {
    top: 3px;
  }

  img {
    width: 46px;
    height: 32px;
  }

  &__department {
    position: relative;
    left: 5px;
    top: 0px;
    max-height: 2rem;
    cursor: pointer;
    display: flex;
    align-items: center;

    > a {
      color: theme-color("placeholder");
      font-weight: bold;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 75px;
      text-decoration: none;

      @include media-breakpoint-up(lg) {
        max-width: 100%;
      }
    }

    &__caret {
      color: theme-color("placeholder");
      display: flex;
      padding: 3px 0;

      svg {
        margin-top: 1px;
        margin-left: 8px;
      }
    }

    &__hint {
      position: absolute;
      bottom: 0;
      right: 4px;
      transform: translate(50%, 100%);
      display: none;
      z-index: 1500;

      .v-navbar-logo__department__caret:hover & {
        display: block;
      }

      a {
        color: theme-color("dark");
        font-size: 1rem;
        white-space: nowrap;
        text-decoration: none;

        &:hover {
          color: theme-color("primary");
        }
      }

      &__wrapper {
        box-shadow: 0 18px 40px 0 rgba(0, 0, 0, 0.3);
        border-radius: $border-radius;
        animation-name: reveal;
        animation-duration: 200ms;
        animation-fill-mode: both;
        animation-timing-function: ease-out;
        animation-delay: 100ms;
        margin-top: 24px;
        background: #fff;
        padding: $spacer;
        position: relative;

        &::before {
          content: "";
          width: 0;
          height: 0;
          border-left: 14px solid transparent;
          border-right: 14px solid transparent;
          border-bottom: 14px solid white;
          position: absolute;
          top: 0;
          left: 50%;
          transform: translate(-50%, -90%);
        }
      }
    }
  }

  @media print {
    width: 96px;
    align-items: center;
    flex-direction: column;

    img {
      margin-top: 10px;
      margin-left: 10px;
      width: 70px;
      height: auto;
    }
  }
}

@media print {
  .v-navbar-logo-wrapper {
    padding: 0 !important;
  }
}
