
@import "../../sass/component.scss";

.v-inline {
  &__content {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;

    > :not(code) {
      .v-inline--xsmall > & {
        margin-bottom: map-get($spacers, 1);
        margin-right: map-get($spacers, 1);
      }
      .v-inline--small > & {
        margin-bottom: map-get($spacers, 2);
        margin-right: map-get($spacers, 2);
      }
      .v-inline--medium > & {
        margin-bottom: map-get($spacers, 3);
        margin-right: map-get($spacers, 3);
      }
      .v-inline--large > & {
        margin-bottom: map-get($spacers, 4);
        margin-right: map-get($spacers, 4);
      }
      .v-inline--xlarge > & {
        margin-bottom: map-get($spacers, 5);
        margin-right: map-get($spacers, 5);
      }
      .v-inline > & {
        &:last-child {
          margin-right: 0;
        }
      }
    }
    .v-inline--center > & {
      justify-content: center;
    }
    .v-inline--right > & {
      justify-content: flex-end;
    }
  }
}
