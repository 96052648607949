
@import "../../sass/component.scss";

.v-contact-widget__icon {
  display: flex;
  width: 52px;
  height: 52px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: theme-color("white");
  box-shadow: $box-shadow-lg;
  cursor: pointer;
}
