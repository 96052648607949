
@import "../../sass/component.scss";

.v-tag {
  display: inline-block;
  padding: 6px 7px 4px 7px;
  border-radius: 5px;
  text-transform: uppercase;
  font-size: 10px;
  white-space: nowrap;
}
