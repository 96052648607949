
@import "../../sass/component.scss";

.v-checkbox {
  cursor: default;
  position: relative;

  &__wrapper {
    position: relative;
    display: flex;
    user-select: none;
  }

  &__input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 24px;
    height: 24px;
    margin: 0;

    &:focus-visible ~ .v-checkbox__checkbox {
      filter: brightness(0.95);
      outline-color: var(--color-focus);
      outline-width: 2px;
      outline-style: solid;
      outline-offset: 0;
      border-color: white !important;
    }
    &:focus:not(:focus-visible) ~ .v-checkbox__checkbox {
      outline: none;
    }

    &:checked ~ .v-checkbox__checkbox {
      background: theme-color("focus");
      border-color: theme-color("focus");

      svg {
        display: block;
        stroke: #fff;
      }
    }

    &:checked:disabled ~ .v-checkbox__checkbox {
      background: #fff;
      border-color: theme-color("border");
      cursor: not-allowed;

      svg {
        stroke: theme-color("border");
      }
    }

    &:checked ~ .v-checkbox__label {
      color: inherit;
    }

    &:checked:disabled ~ .v-checkbox__label {
      color: theme-color("border");
      cursor: not-allowed;
    }
  }

  &__checkbox {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    flex-basis: 24px;
    flex-shrink: 0;
    border-radius: 5px;
    background: #fff;
    border: 1px solid theme-color("border");

    .v-checkbox--error & {
      border-color: theme-color("danger");
    }

    svg {
      display: none;
    }
  }

  &__label {
    color: theme-color("placeholder");
  }

  &__helper {
    margin-top: 5px;
    font-size: 0.75rem;
    color: theme-color("placeholder");

    .v-checkbox--error & {
      color: theme-color("danger");
    }
  }
}
