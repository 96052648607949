
@import "../../sass/component.scss";

.v-table {
  &:not(.v-table--vue) {
    position: relative;
    overflow-x: auto;

    table {
      width: 100%;
      border-collapse: collapse;

      thead {
        text-align: left;

        th {
          font-size: 0.875rem;
          font-weight: normal;
        }
      }

      tbody tr {
        &:nth-child(even) {
          background-color: transparent;
        }
        &:nth-child(odd) {
          background-color: theme-color("light");
        }
      }

      tr {
        border: 0;
      }

      td,
      th {
        border: 0;

        &:nth-of-type(1) {
          border-radius: 5px 0 0 5px;
        }

        &:last-of-type {
          border-radius: 0 5px 5px 0;
        }
      }

      th {
        color: theme-color("gray-dark");
      }

      td {
        padding: $spacer;
      }
    }
  }

  &--overflow {
    > div {
      display: flex;
      overflow-x: auto;
      cursor: grab;

      &:after {
        content: "";
        padding-right: 30px;
      }
    }

    &:after {
      content: "";
      position: absolute;
      height: 100%;
      width: 60px;
      top: 0;
      right: 0;
      background: linear-gradient(
        to left,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 0) 100%
      );
    }
  }
}

@import "./v-table-overrides";
