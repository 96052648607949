
@import "../../../sass/component.scss";

.v-navbar-notifications {
  position: relative;
  user-select: none;

  &__menu-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: theme-color("dark");
    touch-action: manipulation;
    -webkit-tap-highlight-color: transparent;
    font-size: 0.875rem;
    position: relative;
    cursor: pointer;

    @include media-breakpoint-up(md) {
      margin-left: $spacer;
    }

    @include media-breakpoint-up(lg) {
      margin-left: $spacer * 2;
    }

    img {
      margin-bottom: 1px;
      width: 30px;
      height: 30px;

      @include media-breakpoint-down(sm) {
        width: 26px;
        height: 26px;
      }
    }

    &__dot {
      position: absolute;
      top: 1px;
      right: 1px;
      width: 10px;
      height: 10px;
      background-color: theme-color("primary");
      border-radius: 50%;
      transition: opacity 500ms ease-in-out, transform 500ms ease-in-out;

      &-enter {
        transform: scale(0);
        opacity: 0;
      }

      @include media-breakpoint-down(sm) {
        top: 0;
        right: -2px;
      }
    }

    &__arrow {
      position: relative;
      top: -1px;
      margin-left: 2px;

      @include media-breakpoint-down(sm) {
        display: none;
      }
    }

    @include media-breakpoint-down(sm) {
      font-size: 0.625rem;
      line-height: 1em;

      span {
        margin-top: 3px;
      }
    }
  }

  &__list {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-61.5%, 100%);
    width: 370px;
    display: none;
    z-index: 1500;

    .v-navbar-notifications__menu-item--open & {
      display: block;
    }

    @include media-breakpoint-up(lg) {
      .v-navbar-notifications__menu-item:hover & {
        display: block;
      }
    }

    @include media-breakpoint-up(lg) {
      transform: translate(-53%, 100%);
    }

    @include media-breakpoint-up(xl) {
      transform: translate(-50%, 100%);
    }

    &__wrapper {
      box-shadow: 0 18px 40px 0 rgba(0, 0, 0, 0.3);
      border-radius: $border-radius;
      animation-name: reveal;
      animation-duration: 200ms;
      animation-fill-mode: both;
      animation-timing-function: ease-out;
      animation-delay: 100ms;
      margin-top: $spacer;

      &::before {
        content: "";
        width: 0;
        height: 0;
        border-left: 14px solid transparent;
        border-right: 14px solid transparent;
        border-bottom: 14px solid white;
        position: absolute;
        top: 0;
        transform: translate(-50%, -90%);

        @include media-breakpoint-up(md) {
          left: 61.5%;
        }

        @include media-breakpoint-up(lg) {
          left: 53%;
        }

        @include media-breakpoint-up(xl) {
          left: 50%;
        }
      }

      > div {
        background-color: theme-color("white");
        position: relative;
        max-height: 560px;
        overflow-y: auto;
        border-radius: $border-radius;

        .v-loading {
          padding: 100px 0;
        }
      }
    }

    &--mobile {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      height: 100%;
      overflow-y: auto;
      z-index: 2000;
      padding-top: 61px;
      background-color: theme-color("white");
      transition: opacity 250ms ease-out;

      &-enter,
      &-leave-to {
        opacity: 0;
      }

      @include media-breakpoint-up(md) {
        display: none;
      }

      .v-loading {
        padding-top: 60px;
      }

      &__header {
        display: flex;
        align-items: center;
        height: 61px;
        padding-left: $spacer;
        border-bottom: 1px solid theme-color("gray");
        position: fixed;
        top: 0;
        width: 100%;
        background-color: white;
        touch-action: manipulation;
        -webkit-tap-highlight-color: transparent;

        h1 {
          flex-grow: 1;
        }

        &__close {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 10px 16px 10px 8px;
          user-select: none;

          svg {
            width: 26px;
            height: 26px;
            box-sizing: content-box;
            cursor: pointer;
          }

          span {
            font-size: 0.625rem;
            line-height: 1em;
            margin-top: 3px;
          }
        }
      }
    }
  }
}
