// This file is used to override the default styles of the v-table component
// The styles in this file will be applied to specific areas namespaced by the class name of the parent component

.v-accordion:has(.v-table) {

  .v-table {
    // //////////////////////////////////////////
    // VUE v-table component overrides:
    // //////////////////////////////////////////
    &:has(.v-table__table) {
      table {
        tr:nth-child(odd) th,
        tr:nth-child(even) td {
          background: theme-color("white") !important;
        }
        th {
          padding-block: 1rem;
         .v-text{
            font-weight: bold;
            font-size: inherit;
            color: inherit !important;
          }
        }
        .v-cell__text {
          overflow: initial;
          text-overflow: initial;
          white-space: initial;
        }
      }
    }

    // //////////////////////////////////////////
    // SITECORE v-table class overrides:
    // (The Sitecore v-table element does by default not have
    //  a table head element, but we can fake it and style it for both cases)
    // //////////////////////////////////////////
    &:not(:has(.v-table__table)) {
      table {
        // With thead:
        &:has(thead) {
          th {
            font-weight: bold;
            font-size: inherit;
            color: inherit !important;
            background-color: theme-color("white") !important;
          }
          tr:nth-child(even) td {
            background: theme-color("white") !important;
          }
        }
        // If no thead is present:
        &:not(:has(thead)) {
          tr:first-child td { // Fake thead
            font-weight: bold;
          }
          tr:nth-child(odd) td {
            background: theme-color("white") !important;
          }
        }
      }
      // shared styles for both cases:
      th, td {
        max-width: 400px;
      }
      p {
        &:first-child {
          margin-top: 0;
        }
        &:last-child {
          margin-bottom: 0;
        }
        &:empty {
          margin: 0;
        }
      }
    }
  }
}
