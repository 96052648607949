.v-alert-global {
  position: relative;
  color: theme-color("white");
  background: linear-gradient(
    180deg,
    theme-color("primary") 0%,
    darken(theme-color("primary"), 4%) 70%
  );

  & + .v-alert-global {
    border-top: 1px solid theme-color("white");
  }

  &.theme-warning {
    color: theme-color("dark");
    background: linear-gradient(
      180deg,
      theme-color("warning-light") 0%,
      darken(theme-color("warning-light"), 4%) 70%
    );
  }

  &__container {
    display: flex;
  }

  &__icon {
    position: absolute;
    inset-inline-end: 0.8 * $spacer;
    color: inherit;
    svg {
      cursor: pointer;
      position: relative;
      inset-block-start: 0.6 * $spacer;
      border-radius: 4px;
      transition: background-color 100ms, box-shadow 200ms, opacity 200ms;
      &:hover {
        background: lighten(theme-color("primary"), 20%);
        box-shadow: 0 0 0 10px * 0.5 lighten(theme-color("primary"), 20%);
      }
    }
    &.theme-warning {
      svg:hover {
        background: lighten(theme-color("warning-light"), 10%);
        box-shadow: 0 0 0 10px * 0.5 lighten(theme-color("warning-light"), 10%);
        opacity: 0.7;
      }
    }
  }

  &__content {
    width: 100%;
    padding: (0.8 * $spacer) 0;
    overflow: hidden;

    @include media-breakpoint-up(md) {
      text-align: center;
    }

    &.has-icon {
      padding-inline-end: $spacer * 1.75;
    }

    ::v-deep {
      a,
      a:hover,
      .v-text {
        color: theme-color("white");
        font-weight: normal;
      }
      a {
        cursor: pointer;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    &.theme-warning {
      ::v-deep {
        a,
        a:hover,
        .v-text {
          color: theme-color("dark");
        }
      }
    }
  }
}
