
@import "../../sass/component.scss";

.v-embed__wrapper {
  position: relative;
  border-radius: $border-radius;
  overflow: hidden;

  &--aspect-16\:9 {
    padding-top: 56.25%;
  }

  &--aspect-4\:3 {
    padding-top: 75%;
  }

  &--aspect-1\:1 {
    padding-top: 100%;
  }

  ::v-deep {
    iframe,
    embed,
    object {
      position: absolute;
      top: 0;
      left: 0;
      width: 100% !important;
      height: 100% !important;
    }
  }
}
