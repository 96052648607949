
@import "../../sass/component.scss";

.v-contact-widget {
  &--minimized,
  &--maximized {
    position: fixed;
    z-index: 1000;
    right: $spacer;
    transition: all 250ms ease-out;

    &-enter,
    &-leave-to {
      transform: translateY(100px);
      opacity: 0;
    }
  }

  &--minimized {
    bottom: $spacer;
  }

  &--maximized {
    left: $spacer;
    width: calc(100% - 32px);
    bottom: $spacer;

    @include media-breakpoint-up(sm) {
      left: unset;
      width: 480px;
    }
  }

  &__close {
    position: absolute;
    right: 0;
    top: 0;
    padding: 15px;
    cursor: pointer;
    z-index: 1501;

    &__circle {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 26px;
      height: 26px;
      background-color: theme-color("gray");
      transition: background-color 200ms ease-out;
      border-radius: 50%;
    }

    &:hover &__circle {
      background-color: theme-color("gray");
      filter: brightness(0.9);
    }
  }

  &__content {
    padding: $spacer * 2;
    background-size: 0;
    min-height: 415px;
    position: relative;

    @include media-breakpoint-up(sm) {
      background-size: 170px;
      padding-right: 150px;
      min-height: 315px;
    }
  }
  &__image {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 150px;

    @media screen and (max-width: 580px) {
      display: none;
    }
  }
}
