
@import "../../sass/component.scss";

.v-footer-contact {
  position: relative;
  background-color: theme-color("light");

  @include media-breakpoint-up(md) {
    padding: 5 * $spacer 0;
    min-height: 500px;
  }

  &__image {
    position: absolute;
    bottom: 0;
    left: calc(25% - 215px);
    height: 100%;
    width: 430px;
    object-position: 50% 50px;
    object-fit: cover;

    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  @media print {
    display: none;
  }
}
